import { Form, Input, Select } from "antd";

const FormInput = ({ id, placeholder, label, defaultValue }) => {
  return (
    <Form.Item key={id} name={id}>
      <div className="invite-user__field">
        <p className="invite-user__field__name">{label}</p>
        <Input
          bordered={false}
          placeholder={placeholder}
          defaultValue={defaultValue}
          maxLength={1000}
        />
      </div>
    </Form.Item>
  );
};

const FormSelect = ({
  id,
  label,
  options,
  placeholder,
  onSelectChange,
  defaultValue,
}) => {
  return (
    <Form.Item key={id} name={id}>
      <div className="invite-user__field">
        <p className="invite-user__field__name">{label}</p>
        <Select
          bordered={false}
          options={options}
          defaultValue={defaultValue || options.filter((o) => o.default)[0]}
          placeholder={placeholder}
          onChange={(value) =>
            onSelectChange(id, options.filter((o) => o.value === value)[0].id || options.filter((o) => o.value === value)[0].value)
          }
        />
      </div>
    </Form.Item>
  );
};

const InviteContent = ({ fields, onSelectChange }) => {
  const getElement = (field) => {
    switch (field.type) {
      case "input":
        return (
          <FormInput
            id={field.enrollmentResponseId || field.id}
            placeholder={field.placeholder}
            label={field.label}
            defaultValue={field.defaultValue}
          />
        );
      case "select":
        return (
          <FormSelect
            id={field.enrollmentResponseId || field.id}
            placeholder={field.placeholder}
            label={field.label}
            options={field.options}
            onSelectChange={onSelectChange}
            defaultValue={field.defaultValue}
          />
        );
      default:
        return null;
    }
  };

  return fields.map((field) => getElement(field));
};

export default InviteContent;
