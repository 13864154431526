import React, {useEffect, useState} from "react";
import {Divider, Input, Select} from "antd";
import {Icon} from "../../icon/icon";
import classNames from "classnames";

const { Option } = Select;

export const FormSelectWithSearch = ({
 onSelect = () => {},
 defaultValue,
 disabled,
 options = [],
 canAddNew,
 isURL,
 readonly,
 multiline,
 addBtnText = "Add Source URL",
 ...props
}) => {
  const [selectOptions, setSelectOptions] = useState(options);
  const [searchValue, setSearchValue] = useState();
  const [value, setValue] = useState(defaultValue);
  const [showUrlError, setShowUrlError] = useState();

  useEffect(() => {
    if (showUrlError) {
      setShowUrlError(false);
    }
  }, [searchValue]);

  const handleSelect = (v) => {
    setValue(v);
    onSelect(v);
  };

  const addNewOption = () => {
    if (!searchValue) return;
    if (isURL && !searchValue.includes("http")) {
      setShowUrlError(true);
      return;
    }

    const newOption = { value: searchValue.trim(), id: null, subLabel: null };
    setSelectOptions([...selectOptions, newOption]);
    setSearchValue("");
    setValue(newOption);
    onSelect(newOption);
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const renderDropdown = () => (
    <div>
      <div>
        <Input
          value={searchValue}
          bordered={false}
          placeholder="Find or add new link"
          onChange={handleSearch}
          maxLength={2000}
          prefix={
            <Icon
              name="search"
              color="text-2"
              size={12}
              hidden={readonly || disabled}
            />
          }
        />
        <Divider style={{ margin: 0 }} />
      </div>
      <div className="lf-form-select-option-box">
        {selectOptions.filter((option) =>
          searchValue ? option.value.includes(searchValue) : true
        ).length ? (
          selectOptions
            .filter((option) =>
              searchValue ? option.value.includes(searchValue) : true
            )
            .map((option) => (
              <div
                key={option.value}
                className={classNames(
                  "lf-form-select-search-box lf-form-select-option",
                  {
                    "lf-form-select-option--selected":
                      value?.value === option?.value,
                    "lf-form-select-option--flex": option.subLabel,
                  }
                )}
                onClick={() => handleSelect(option)}
              >
                {option.subLabel && (
                  <span className="lf-form-select-option-label">
                    {option.subLabel}
                  </span>
                )}
                <span
                  className={
                    option.subLabel
                      ? "lf-form-select-option-sub-label"
                      : "lf-form-select-option-label"
                  }
                >
                  {option.value}
                </span>
              </div>
            ))
        ) : (
          <div className="lf-form-select-search-box" onClick={addNewOption}>
            {showUrlError ? (
              <span className="lf-form-select-search-url--error">
                Please enter a valid URL
              </span>
            ) : (
              <>
                <Icon name="add" size={12} hidden={readonly || disabled} />
                <span>{addBtnText}</span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div
      className={classNames("lf-form-select", {
        "lf-form-select--readonly": readonly || disabled,
      })}
    >
      <Select
        value={value}
        disabled={disabled}
        onSelect={handleSelect}
        bordered={false}
        className={multiline ? "lf-select-multiline" : ""}
        dropdownRender={renderDropdown}
        destroyOnClose
        suffixIcon={
          <Icon
            name="arrow-down"
            color="text-2"
            size={12}
            hidden={readonly || disabled}
          />
        }
        {...(readonly ? { onSelect: undefined } : {})}
        {...props}
      >
        {selectOptions.map((option) => (
          <Option key={option.value} value={option.value}>
            <div style={{ padding: "10px 0px" }}>
              {option.subLabel && (
                <div className="lf-form-select-option-label">
                  {option.subLabel}
                </div>
              )}
              <div
                className={
                  option.subLabel
                    ? "lf-form-select-option-sub-label"
                    : "lf-form-select-option-label"
                }
              >
                {option.value}
              </div>
            </div>
          </Option>
        ))}
      </Select>
      {readonly && <div className="lf-form-select__readonly" />}
    </div>
  );
};
