import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNotification} from "../../notification/notification";
import classNames from "classnames";
import Dragger from "antd/es/upload/Dragger";
import {Button} from "antd";
import {ImgSkeleton} from "./ImgSkeleton";
import {LazyLoadImage} from "react-lazy-load-image-component";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const FormImage = ({
  value,
  onChange,
  readonly,
  size,
  articleId,
  notificationPath="articles.notification",
  fetchIconApi,
  updateImageStatus,
  updateIsUpdatedImage,
  form,
  fetchFromFormKeyAutorized,
  fetchFromFormKeyUrl,
  ...rest
}) => {
  const imageInstanse = new Image();
  const [imageUrl, setImageUrl] = useState();
  const [hasImage, setHasImage] = useState(null);
  const [removed, setRemoved] = useState(false);
  const [isExternalFetching, setIsExternalFetching] = useState(false);
  const {openNotification} = useNotification();
  const {t} = useTranslation();

  const updateFormImageValue = (value) => {
    if (updateImageStatus) {
      updateImageStatus(value);
    }
  };

  const handleChange = (info) => {
    getBase64(info.file.originFileObj, (url) => {
      setImageUrl(url);
      onChange(url);
      updateFormImageValue(true);
      updateIsUpdatedImage(true);
      if (removed) {
        setRemoved(false);
      }
    });
  };

  const uploadFromLink = async (imageUrl) => {
    if (!atob(imageUrl).startsWith("http")) {
      openNotification({
        type: "error",
        message: t(`${notificationPath}.invalid_link`),
      });
      return;
    }
    setIsExternalFetching(true);
    try {
      const response = await fetchIconApi({ url: imageUrl })
      const imageContent = response?.data?.image;

      setImageUrl(imageContent);
      onChange(imageContent);
      updateFormImageValue(true);
      updateIsUpdatedImage(true);
      if (removed) {
        setRemoved(false);
      }
    } catch (e) {
      openNotification({
        type: "error",
        message: t(`Error fetching external image`),
      });
    } finally {
      setTimeout(() => {
        setIsExternalFetching(false);
        setHasImage(false);
      }, 1000);
    }
  }

  const removeImageUrl = () => {
    setImageUrl(null);
  };

  const changeImageStatus = (status) => {
    if (status) {
      removeImageUrl();
    }
    setHasImage(status);
    updateFormImageValue(status);
  };

  const imageIsFetching = hasImage === null && !imageUrl;
  const imageFailedToFetch = hasImage === false && !imageUrl;

  useEffect(() => {
    if (value) {
      imageInstanse.src = value;
      setImageUrl(imageInstanse.src);
    } else {
      removeImageUrl();
    }
  }, [value]);

  return (
    <div
      className={classNames("lf-form-image", {
        "lf-form-image--has-image": !!imageUrl || hasImage,
        "lf-form-image--readonly": readonly,
      })}
    >
      <Dragger
        onChange={handleChange}
        showUploadList={false}
        disabled={readonly}
      >
        {!readonly && (
          <div className={"lf-form-image__button-group"}>
            <Button
              className={"lf-form-image__button-upload"}
              type={"primary"}
              style={{ marginRight: 8 }}
            >
              Upload
            </Button>
            <Button
              className="lf-form-image__button-remove"
              style={{ marginRight: 8 }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();

                const urlValue = form.getFieldValue(fetchFromFormKeyUrl);
                const imgSource = btoa(urlValue)
                uploadFromLink(imgSource);
              }}
            >
              Fetch from link
            </Button>
            {imageUrl && (
              <Button
                className={"lf-form-image__button-remove"}
                onClick={(e) => {
                  e.stopPropagation();
                  setImageUrl(true);
                  onChange("");
                  setHasImage(false);
                  updateFormImageValue(false);
                  updateIsUpdatedImage(true);
                  setRemoved(true);
                }}
              >
                Remove
              </Button>
            )}
          </div>
        )}

        <div
          className={"lf-form-image__inside"}
          style={{
            backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
            paddingBottom: imageUrl || removed ? "60%" : null,
            backgroundSize: "109% auto",
            maxHeight: "369px",
            overflow: "hidden",
          }}
        >
          {hasImage !== false && !imageUrl && !removed && !isExternalFetching ? (
            <LazyLoadImage
              src={`${
                process.env.REACT_APP_DEV_URL || document.location.origin
              }/api/v1/pages/${articleId}/icon?${Date.now()}`}
              effect="blur"
              loading="lazy"
              onError={() => {
                changeImageStatus(false);
                updateIsUpdatedImage(true);
              }}
              onLoad={() => changeImageStatus(true)}
              height={369}
              width={"100%"}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                scale: "1.09",
              }}
            />
          ) : null}
          {(imageIsFetching || isExternalFetching) && (
            <div className="lf-form-image__skeleton">
              <ImgSkeleton width={"100%"} proportion={60} active />
            </div>
          )}
          {imageFailedToFetch && readonly && (
            <div className="lf-form-image__skeleton">
              <ImgSkeleton width={"100%"} proportion={60} withIcon />
            </div>
          )}
        </div>
      </Dragger>
    </div>
  );
};
