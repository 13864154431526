import React, {forwardRef} from "react";
import {Input} from "antd";
import classNames from "classnames";

export const FormInput = forwardRef(
  (
    {
      name,
      defaultValue,
      maxLength,
      readonly,
      editMode,
      disabled,
      type = "text",
      className,
      bordered = true,
      onChange = () => {},
      ...rest
    },
    ref
  ) => {
    return (
      <Input
        className={classNames(className, {
          "lf-form-element": true,
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        bordered={bordered}
        type={type}
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        onChange={onChange}
        data-1p-ignore
        {...rest}
        ref={ref}
      />
    );
  }
);

