import "./styles.css";
import useScenarioSimulatorController from "./controllers/useSimulatorController";
import MultipleChoice from "./components/multiple-choice";
import { useEffect, useMemo, useState } from "react";
import Question from "./components/question";
import Task from "./components/task";
import SuggestionsCarousel from "./components/suggetions";
import TextInput from "./components/text-input";
import SingleChoice from "./components/single-choice";
import Picker from "./components/picker";
import DateTimePicker from "./components/dateTimePicker";
import TreeDotsLoader from "./components/tree-dots-loader/tree-dots-loader";
import FitFactCard from "./components/fitfact-card";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import ApiScenariosManagement from "../../api/scenarios-management";

const ScenarioSimulator = ({
  id,
  scenario,
  tasks,
  scenarioVersionId,
}) => {
  const [facts, setFacts] = useState([]);
  const [isReady, setReady] = useState();
  const [embeddedScenarios, setEmbeddedScenarios] = useState({});
  const [suggestionArticles, setSuggestionArticles] = useState([]);
  const { history, setAnswer, inProcess } =
    useScenarioSimulatorController(scenario, id, embeddedScenarios, isReady);

  const setAnswerValue = (id, answer) => {
    const isAlreadyAnswered = history.find((node) => node?.id === id && node?.answer);

    setAnswer(id, answer, isAlreadyAnswered);
  }

  useEffect(() => {
    ApiScenariosManagement.getSimulatorResources(scenarioVersionId).then(
      (r) => {
        setSuggestionArticles(r.data.pages || {})
        setFacts(r.data.facts || []);
        setEmbeddedScenarios(r.data.embeddedScenarios);
        setReady(true);
      }
    );
  }, [scenario]);

  const renderItem = ({ data, id }) => {
    if (data.nodeType?.includes("task:")) {
      return (
        <Task
          key={data.id}
          data={tasks.find((t) => t.title === (data.name || data.title))}
        />
      );
    }

    switch (data.nodeType) {
      case "message:single":
        return (
          <SingleChoice
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answer?.label
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );
      case "message:multi":
        return (
          <MultipleChoice
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answers
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );

      case "message:info":
        return <Question text={data.message} />;

      case "message:suggestions":
        return (
          <SuggestionsCarousel
            key={data.id}
            pages={suggestionArticles[id] || []}
          />
        );

      case "message:input":
        return (
          <TextInput
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answer
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );

      case "message:picker":
        return (
          <Picker
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answer
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );

      case "message:date":
        return (
          <DateTimePicker
            key={data.id}
            data={data}
            answeredLabel={
              history.find((node) => node?.id === id)?.answer
            }
            setAnswer={(answer) => setAnswerValue(id, answer)}
          />
        );

      case "fit:facts":
        return (
          <FitFactCard
            key={data.id}
            data={Object.entries(facts).find(([key, value]) => key === id)?.[1]}
          />
        );

      default:
        return;
    }
  };

  const renderHistory = useMemo(() => {
    return history.map((item) => renderItem(item));
  }, [history, setAnswer]);

  return (
    <div className="scenario-simulator-layout">
      <div className="scenario-simulator-content-container">
        {isReady ? (
          <>
            {renderHistory}
            {inProcess && <TreeDotsLoader />}
          </>
        ) : (
          <Spin
            className="scenario-simulator-content-loader"
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        )}
      </div>
    </div>
  );
};

export default ScenarioSimulator;
