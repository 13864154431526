import classNames from "classnames";
import {Select} from "antd";
import {Icon} from "../../icon/icon";
import React from "react";

export const FormSelect = ({
  onSelect = () => {},
  defaultValue,
  disabled,
  options,
  readonly,
  ...props
}) => {
  return (
    <div
      className={classNames("lf-form-select", {
        "lf-form-select--readonly": readonly || disabled,
      })}
    >
      <Select
        options={options}
        disabled={disabled}
        defaultValue={defaultValue}
        onSelect={onSelect}
        bordered={false}
        suffixIcon={
          <Icon
            name={"arrow-down"}
            color={"text-2"}
            size={12}
            hidden={readonly || disabled}
          />
        }
        {...(readonly ? { onSelect: undefined } : {})}
        {...props}
      />
      {readonly && <div className={"lf-form-select__readonly"} />}
    </div>
  );
};

