import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import ScenarioTable from "./components/scenario-table";
import ScenarioPage from "./components/scenario-page";
import useNotification from "../../../components/notification/notification";
import { Body } from "../../../components/main-body/body";
import "./styles.css";
import ApiScenariosManagement from "../../../api/scenarios-management";
import DropdownFilters from "../../../components/dropdown-filters/dropdown-filters";
import debounce from "../../../common/utils/debounce";

const ScenarioManagement = ({mode}) => {
  const [scenarios, setScenarios] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [selectedScenario, setSelectedScenario] = useState({});
  const [tasks, setTasks] = useState([]);
  const { t } = useTranslation();
  const { openNotification } = useNotification();
  const [loading, setLoading] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [filters, setFilters] = useState({});
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const getScenarios = async (deselect, query = "") => {
    setLoading(true);
    const data = await ApiScenariosManagement.getScenarios({ query, ...filters  });
    setScenarios(data.data.scenarios);
    setAuthors(data.data.authors);
    if (selectedScenario.id && !deselect) {
      setSelectedScenario(
        data.data.scenarios.find((s) => s.id === selectedScenario.id) ?? {}
      );
    }
    setLoading(false);
  };

  const debouncedGetScenarios = useCallback(debounce(getScenarios, 400), [filters, selectedScenario.id]);

  const handleUpdateSearchValue = (value) => {
    setSearchValue(value);
    debouncedGetScenarios(false, value);
  };

  const getDailyTasks = async () => {
    return ApiScenariosManagement.getDailyTasks().then((r) => {
      setTasks(r.data.dailyTasks);
    });
  };

  useEffect(() => {
    getScenarios();
    getDailyTasks();
  }, [filters]);

  useEffect(() => {
    if (!id && selectedScenario.id) {
      handleDeselectScenario();
    }
  }, [id, selectedScenario.id]);

  const createScenario = () => {
    setLoading(true);
    ApiScenariosManagement.createScenario()
      .then((r) => {
        openNotification({
          type: "success",
          message: (
            <p>
              {t("administration.scenario.notification.created", {
                scenario: r.data.scenario.title,
              })}
            </p>
          ),
        });

        setSelectedScenario(r.data.scenario);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteScenario = (id, value) => {
    setLoading(true);

    ApiScenariosManagement.deleteScenario(id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.deleted", {
                  scenario: value,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDuplicate = (scenario) => {
    setLoading(true);

    ApiScenariosManagement.duplicateScenario(scenario.id)
      .then(() => {
        getScenarios().then(() => {
          openNotification({
            type: "success",
            message: (
              <p>
                {t("administration.scenario.notification.duplicated", {
                  scenario: scenario.title,
                })}
              </p>
            ),
          });
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: (
            <p>
              {t("administration.scenario.notification.not_implemented", {
                scenario: "",
              })}
            </p>
          ),
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (id && scenarios.length) {
      const scenario = scenarios.find((scenario) => scenario.id === id);
      if (scenario) {
        setSelectedScenario(scenario);
        setOpen(true);
        handleNavigate(`/administration/scenarios/${scenario.id}`);
      } else if (mode !== 'show') {
        setSelectedScenario({});
        setOpen(false);
      }
    }
  }, [id, scenarios]);

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const handleSelectScenario = (scenario) => {
    setSelectedScenario(scenario);
    setOpen(true);
    handleNavigate(`/administration/scenarios/${scenario.id}`);
  };

  const handleDeselectScenario = async () => {
    setSelectedScenario({});
    handleNavigate(`/administration/scenarios`);
    await getScenarios(true);
  };


  const headerProps = useMemo(
    () => ({
      title: "Scenario Management",
      create: {
        label: t("administration.scenario.create"),
        onClick: createScenario,
      },
      search: {
        value: searchValue,
        onHandleChange: handleUpdateSearchValue,
      },
    }),
    [searchValue, isOpen, selectedScenario]
  );

  const updateFilterValue = (value) => setFilters(value);

  const updateScenarioAuthor = async (scenarioVersionId, authorId) => {
    try {
      await ApiScenariosManagement.updateScenarioVersion(scenarioVersionId, { userId: authorId });

      // getScenarios();
    } catch (error) {

    } finally {

    }
  };

  return (
    <Body header={headerProps}
      filters={
        <DropdownFilters
        filtersBy={[
          { name: "Author", id: "userIds", children: authors }
        ]}
        updateFilterValue={updateFilterValue}
        />
      }
    >
      {selectedScenario?.id ? (
        <ScenarioPage
          scenario={selectedScenario}
          toList={handleDeselectScenario}
          refreshScenario={getScenarios}
          openNotification={openNotification}
          api={ApiScenariosManagement}
          tasks={tasks}
          authors={authors}
          updateScenarioAuthor={updateScenarioAuthor}
        />
      ) : (
        <ScenarioTable
          data={scenarios}
          onHandleRowClick={handleSelectScenario}
          deleteScenario={deleteScenario}
          handleDuplicate={handleDuplicate}
          loading={loading}
        />
      )}
    </Body>
  );
};

export default ScenarioManagement;
