import { memo, useEffect } from "react";
import "./sidebar.css";
import { Button, Menu } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon } from "../icon/icon";

export const Sidebar = memo(({ title, items, onBack }) => {
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const selectHandler = (item) => {
    const { key } = item;
    navigate(key);
  };

  const selectedKey = (() => {
    if (`${pathname}${hash}` === "/administration") return "/administration/tasks";
    if (`${pathname}${hash}` === "/subject-management") return "/subject-management#subjects";
    if (`${pathname}${hash}` === "/audit-trail") return "/audit-trail#users";
    return `${pathname}${hash}` || pathname;
  })();

  useEffect(() => {
    console.log("pathname", pathname, hash);
  }, [pathname]);

  return (
    <div className={"lf-sidebar"}>
      <div className="lf-sidebar__header">
        {onBack && (
          <Button
            icon={<Icon name={"arrow-left"} />}
            style={{ marginRight: 16, marginLeft: -12 }}
            onClick={onBack}
          />
        )}
        <h2>{title}</h2>
      </div>
      <div className="lf-sidebar__body">
        <Menu
          selectedKeys={[selectedKey]}
          items={items}
          onSelect={selectHandler}
        />
      </div>
    </div>
  );
});
