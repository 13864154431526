import UserActivityStatus from "../../user-activity-status/user-activity-status";
import React from "react";

export const FormUserStatus = ({ status }) => {
  return (
    <div className="lf-form-user-status">
      <UserActivityStatus userStatus={status} />
    </div>
  );
};
