import {useEffect, useState} from "react";

const serializeByKeyValue = ({storageSelector, keyId, eTagValue}) => {
  try {
    const rawCacheValue = localStorage.getItem(storageSelector);
    const parsedCacheValue = rawCacheValue ? JSON.parse(rawCacheValue) : {};

    parsedCacheValue[keyId] = eTagValue;
    localStorage.setItem(storageSelector, JSON.stringify(parsedCacheValue));
  } catch (e) {
    console.error('Failed to serialize image cache:', e);
  }

  return eTagValue;
}

const deserealizeByKeyValue = ({storageSelector, keyId}) => {
  try {
    const rawCacheValue = localStorage.getItem(storageSelector);
    const parsedCacheValue = rawCacheValue ? JSON.parse(rawCacheValue) : {};

    return parsedCacheValue[keyId];
  } catch (e) {
    console.error('Failed to deserialize image cache:', e);
  }
}

export const useImageCache = ({ storageSelector, keyId, originalImgSrc }) => {
  const [eTagValue, setETagValue] = useState(() => {
    return keyId ? deserealizeByKeyValue({ storageSelector, keyId }) : null;
  });

  useEffect(() => {
    if (keyId) {
      const storedValue = deserealizeByKeyValue({ storageSelector, keyId });
      setETagValue(storedValue);
    }
  }, [keyId, storageSelector]);

  useEffect(() => {
    const loadImage = async () => {
      const response = await fetch(`${originalImgSrc}`, {
        method: 'HEAD',
        headers: {
          cache: 'no-cache',
        }
      });
      const respondedEtag = response.headers.get('ETag');
      if (respondedEtag !== eTagValue || !eTagValue) {
        console.log('[Refactoring] Updating image:', keyId);
        setValue(respondedEtag, keyId);
      }
      if (respondedEtag === eTagValue) {
        console.log('[Refactoring] Using cached image:', keyId);
      }
    };

    if (keyId) {
      loadImage();
    }
  }, [originalImgSrc, keyId, eTagValue]);

  const setValue = (value, newKeyId) => {
    const activeKeyId = newKeyId || keyId;
    if (activeKeyId) {
      setETagValue(serializeByKeyValue({ storageSelector, keyId: activeKeyId, eTagValue: value }));
    }
  };

  const cachedImgSrc = `${originalImgSrc}?${eTagValue}`;

  return {
    eTagValue,
    setETagValue: setValue,
    cachedImgSrc
  };
};
