import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Card from "../../../../components/card/card";
import PlanCalendar from "./calendar";
import { Button, Input, Modal, Spin, Tabs, Dropdown, Menu } from "antd";
import type { TabsProps } from 'antd';
import { Icon } from "../../../../components/icon/icon";
import UserActivityStatus from "../../../../components/user-activity-status/user-activity-status";
import TagsSelect from "../../../../components/tags-select/tags-select";
import ApiPlanManagement from "../../../../api/plan-management";
import { LoadingOutlined } from "@ant-design/icons";
// import type { DragEndEvent } from '@dnd-kit/core';
// const { TabPane } = Tabs;

// import { DndContext, useSensor, PointerSensor } from '@dnd-kit/core';
// import { SortableContext, arrayMove, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable';
// import { CSS } from '@dnd-kit/utilities';

// const DraggableTab = ({ id, children }) => {
//   const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
//   const style = {
//     transform: CSS.Transform.toString(transform),
//     transition,
//   };
//   return (
//     <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
//       {children}
//     </div>
//   );
// };

const DiscardModalComponent = ({
  title,
  handleOk,
  handleCancel,
  isModalOpen,
}) => {
  return (
    <Modal
      title={title}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okButtonProps={{ danger: true }}
      okText="Discard changes"
      className="plan-page-action-modal"
    >
      <p>Are you sure you want to discard changes?</p>
    </Modal>
  );
};

const RemoveModalComponent = ({
  isModalOpen,
  handleOk,
  handleCancel,
  title,
}) => (
  <Modal
    title={title}
    open={isModalOpen}
    onOk={handleOk}
    onCancel={handleCancel}
    okButtonProps={{ danger: true }}
    okText="Remove"
    cancelText="Cancel"
    className="plan-page-action-modal"
  >
    <p>All items mapped to the removed week will disappear from the plan</p>
  </Modal>
);

const normalizeGroups = (group = []) => {
  return group.map((cohort) => ({
    id: cohort.id,
    label: cohort.EnrollmentFieldOption.value,
  }));
};

const PlanPage = ({
  plan,
  cohorts,
  scenarios,
  toList,
  refreshPlan,
  openNotification,
}) => {
  const { t } = useTranslation();
  const { title, id } = plan || {};

  const [planTasks, setPlanTasks] = useState([]);
  const [planScenarios, setPlanScenarios] = useState([]);
  // const [weekIndex, setWeekIndex] = useState(0);
  const [versionStatus, setVersionStatus] = useState(null);
  // const [weeklyScheduleId, setWeeklyScheduleId] = useState();
  const [planActiveCohortsIds, setPlanActiveCohortsIds] = useState([]);
  const [isOpenDiscardModal, setOpenDiscardModal] = useState();
  const [isLoading, setLoading] = useState(true);
  const [inProcess, setProcess] = useState(false);
  const [activeWeek, setActiveWeek] = useState(0);
  const [weeklySchedules, setWeeklySchedules] = useState([]);
  const [isRemoveModalOpen, setRemoveModalOpen] = useState(false);
  const [weekToRemove, setWeekToRemove] = useState(null);
  
  // const sensors = [useSensor(PointerSensor)];

  useEffect(() => {
    let { WeeklySchedules } = plan;
    WeeklySchedules = WeeklySchedules?.filter(
      (ws) => ws.status === "pending" || ws.status === "activeCopy"
    ).sort((a, b) => a.weekIndex - b.weekIndex);

    const { tasks, scenarios } = WeeklySchedules?.[activeWeek] || {};
    setPlanTasks(tasks?.sort((a, b) => a.position - b.position));
    setWeeklySchedules(WeeklySchedules)
    const status = WeeklySchedules.some(schedule => schedule.status === 'pending') 
      ? 'pending' :
      plan.status === 'pending' ? 'pending'
      : 'activeCopy';
    setVersionStatus(status);

    setPlanScenarios(scenarios?.sort((a, b) => a.position - b.position));
    setPlanActiveCohortsIds(WeeklySchedules?.[0].group || []);
    // setWeeklyScheduleId(id);
    if (plan) {
      setLoading(false);
    }
  }, [plan, activeWeek]);

  // const handleDragEnd = (event) => {
  //   const { active, over } = event;
  //   if (active.id !== over.id) {
  //     setWeeklySchedules((items) => {
  //       const oldIndex = items.findIndex(item => item.id === active.id);
  //       const newIndex = items.findIndex(item => item.id === over.id);
  //       const reorderedItems = arrayMove(items, oldIndex, newIndex);

  //       reorderedItems.forEach((item, index) => {
  //         ApiPlanManagement.reorderWeek(item.id, index)
  //           .then(() => {
  //             refreshPlan();
  //             const activeTabId = reorderedItems[newIndex].id;
  //             // onChangeWeek(activeTabId);
  //             setActiveWeek(index);
  //             console.error('Reorder OK');
  //           })
  //           .catch((error) => {
  //             console.error('Reorder ERROR: ----', error);
  //           });
  //       });

  //       return reorderedItems;
  //     });
  //   }
  // };

  const onBack = () => {
    toList();
  };

  const addTaskToPlan = (task) => {
    setPlanTasks((planTasks) => [...planTasks, task]);
  };

  const addScenarioToPlan = (scenario) => {
    setPlanScenarios([...planScenarios, scenario]);
  };

  const updatePlanTitle = (e) => {
    const { value } = e.target || {};
    ApiPlanManagement.updatePlan(id, { title: value });
  };

  const updatePlanCohorts = (value) => {
    const newCohortsList = value.map((v) => v.id);
    setPlanActiveCohortsIds(newCohortsList);
    ApiPlanManagement.updatePlanCohort(plan.id, {
      groupScheduleIds: newCohortsList,
    }).finally(() => {
      refreshPlan();
    });
  };

  const updateTaskPlan = (plan) => {
    setPlanTasks(plan);
  };

  const updateScenariosPlan = (plan) => {
    setPlanScenarios(plan);
  };

  const discardPlan = () => {
    ApiPlanManagement.discardPlan(plan.id).then(() => {
      refreshPlan();
    });
    openNotification({
      type: "success",
      message: <p>{t("plan_management.notification.discard")}</p>,
    });
  };

  const addWeek = () => {
    ApiPlanManagement.addWeek(plan.id).then(() => {
      refreshPlan();
    });
  };

  const activatePlan = () => {
    setProcess(true);
    if (!inProcess) {
      ApiPlanManagement.activatePlan(plan.id)
        .then(() => {
          refreshPlan();

          openNotification({
            type: "success",
            message: <p>{t("plan_management.notification.activated")}</p>,
          });
        })
        .catch((e) => {
          if (e?.response?.data?.error === "GroupsAlreadyUsed") {
            const cohorts = e?.response?.data?.groupsAlreadyUsed.reduce(
              (acc, cohort) => `${acc} ${cohort.EnrollmentFieldOption.value}`,
              ""
            );

            const plansTitles = e?.response?.data?.plans.reduce(
              (acc, plan) => `${acc} ${plan.title}`,
              ""
            );

            openNotification({
              type: "error",
              message: (
                <p>
                  {t("plan_management.notification.errorGroups", {
                    cohorts,
                    plans: plansTitles,
                  })}
                </p>
              ),
            });
          }
        })
        .finally(() => {
          setProcess(false);
        });
    }
  };

  const openDiscardModal = () => {
    setOpenDiscardModal(true);
  };

  const closeDiscardModal = () => {
    setOpenDiscardModal(false);
  };

  const handleDiscardOk = () => {
    discardPlan();
    closeDiscardModal();
  };

  const handleDiscardCancel = () => {
    closeDiscardModal();
  };

  const onChangeWeek = (key) => {
    const selectedWeek = weeklySchedules.find((week) => week.id === key);
    const index = selectedWeek.weekIndex
    setActiveWeek(index);
    refreshPlan();
  };

  const onAddTab = (key: string) => {
    addWeek();
  };

  const items: TabsProps['items'] = weeklySchedules.map((schedule) => ({
    key: schedule.id,
    label: `Week ${schedule.weekIndex + 1}`,
  }));


  // const items: DragEndEvent['items'] = weeklySchedules.map((schedule) => ({
  //   key: schedule.id,
  //   label: (
  //     <DraggableTab id={schedule.id}>
  //       Week {schedule.weekIndex + 1}
  //     </DraggableTab>
  //   ),
  // }));
  // const items = weeklySchedules.map((schedule) => ({
  //   key: schedule.id,
  //   label: (
  //     <DraggableTab id={schedule.id}>
  //       Week {schedule.weekIndex + 1}
  //     </DraggableTab>
  //   ),
  // }));

  const handleMoveLeftWeek = (id) => {
    let weekIndex = items.findIndex(item => item.key === id);
    if (weekIndex > 0) {
      weekIndex -= 1
    }
    ApiPlanManagement.reorderWeek(id, weekIndex).then(() => {
      refreshPlan();
      setActiveWeek(weekIndex);
    });
    console.log("Move left action triggered");
  };


  const handleMoveRightWeek = (id) => {
    let weekIndex = items.findIndex(item => item.key === id);
    if (weekIndex < items.length - 1) {
      weekIndex += 1
    }
    ApiPlanManagement.reorderWeek(id, weekIndex).then(() => {
      refreshPlan();
      setActiveWeek(weekIndex);
    });
    console.log("Move right action triggered");
  };

  const handleDuplicateWeek = (id) => {
    ApiPlanManagement.duplicateWeek(id).then(() => {
      refreshPlan();
    });
    console.log("Duplicate action triggered");
  };

  // const handleRemoveWeek = (id) => {
  //   const selectedWeek = weeklySchedules.find((week) => week.id === id);
  //   const index = selectedWeek.weekIndex
  //   if (index + 1 === weeklySchedules.length) {
  //     setActiveWeek(index - 1);
  //   }
  //   ApiPlanManagement.deleteWeek(id).then(() => {
  //     refreshPlan();
  //   });
  //   console.log("Remove action triggered");
  // };

  const openRemoveModal = (id) => {
    setWeekToRemove(id);
    setRemoveModalOpen(true);
  };

  const closeRemoveModal = () => {
    setRemoveModalOpen(false);
    setWeekToRemove(null);
  };

  const handleRemoveConfirm = () => {
    const selectedWeek = weeklySchedules.find((week) => week.id === weekToRemove);
    const index = selectedWeek.weekIndex;
    
    if (index + 1 === weeklySchedules.length) {
      setActiveWeek(index - 1);
    }
    
    ApiPlanManagement.deleteWeek(weekToRemove).then(() => {
      refreshPlan();
      closeRemoveModal();
    });
    console.log("Remove action triggered");
  };

  const renderTabLabel = (label, id) => {
    const isOnlyOneWeek = weeklySchedules.length === 1;
    let index = weeklySchedules.findIndex( x => x.id === id)
    const isFirst = index === 0
    const isLast = index === weeklySchedules.length - 1
    const menu = (
      <Menu>
        <Menu.Item key="moveLeft" onClick={() => handleMoveLeftWeek(id)} disabled={isFirst}>{t("plan_management.tabs.move_left")}</Menu.Item>
        <Menu.Item key="moveRight" onClick={() => handleMoveRightWeek(id)} disabled={isLast}>{t("plan_management.tabs.move_right")}</Menu.Item>
        <Menu.Item key="duplicate" onClick={() => handleDuplicateWeek(id)}>{t("plan_management.tabs.duplicate")}</Menu.Item>
        <Menu.Item key="remove" onClick={() => openRemoveModal(id)} disabled={isOnlyOneWeek}>
          {t("plan_management.tabs.remove")}
        </Menu.Item>
      </Menu>
    );

    return (
      <span style={{ display: 'flex', alignItems: 'center' }}>
        {label}
        <Dropdown overlay={menu} trigger={['click']}>
          <span
            style={{
              marginLeft: 8,
              fontSize: 20,
              fontWeight: 'bold',
              cursor: 'pointer',
              lineHeight: 1
            }}
          >
            ⋮
          </span>
        </Dropdown>
      </span>
    );
  };

  const tabItems = items.map((item) => ({
    ...item,
    label: renderTabLabel(item.label, item.key),
  }));

  const selectedWeekNumber = () => {
    const selectedWeek = weeklySchedules.find((week) => week.id === weekToRemove);
    const index = selectedWeek?.weekIndex;
    return index + 1
  };

  return (
    <div className="plan-page">
      {isLoading ? (
        <div className="plan-page-loading-box">
          <Spin
            className="plan-page-loading"
            indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          />
        </div>
      ) : (
        <>
          <div className="plan-page-header">
            <div className="plan-page-header-right">
              <Button
                icon={<Icon name={"arrow-left"} />}
                style={{ marginRight: 16 }}
                onClick={onBack}
              />
              <Input
                defaultValue={title}
                bordered={false}
                onChange={updatePlanTitle}
                className="plan-page-name"
              />
            </div>
            <div className="plan-page-header-left">
              {versionStatus === "pending" ? (
                <>
                  <Button
                    onClick={openDiscardModal}
                    className="plan-page-header-left-btn"
                    disabled={inProcess}
                  >
                    {t("plan_management.discard_changes")}
                  </Button>
                  <Button
                    type="primary"
                    onClick={activatePlan}
                    className="plan-page-header-left-btn"
                    loading={inProcess}
                  >
                    {plan.status === "active" || plan.status === "pending"
                      ? t("plan_management.save")
                      : t("plan_management.activate")}
                  </Button>
                  <DiscardModalComponent
                    isModalOpen={isOpenDiscardModal}
                    handleOk={handleDiscardOk}
                    handleCancel={handleDiscardCancel}
                    title={"Discard changes"}
                  />
                </>
              ) : null}
            </div>
          </div>
          <div className="plan-info">
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.table.cohort")}
              </p>
              <TagsSelect
                defaultValue={[
                  ...cohorts,
                  ...normalizeGroups(plan.activeGroups),
                ].filter((cohort) => planActiveCohortsIds?.includes(cohort.id))}
                options={[...cohorts, ...normalizeGroups(plan.activeGroups)]}
                onChange={updatePlanCohorts}
                selectPlaceholder="Select cohort"
              />
            </div>
            <div className="plan-info-item-container">
              <p className="plan-info-item-title">
                {t("plan_management.status")}
              </p>
              <UserActivityStatus userStatus={plan.status} fontSize={14} />
            </div>
          </div>
          <div>
            {/*<Card title={t("plan_management.weekly_plan")}>*/}
            <Card>
{/*              <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                <SortableContext items={weeklySchedules} strategy={verticalListSortingStrategy}>*/}
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Tabs 
                      defaultActiveKey={activeWeek} 
                      items={tabItems} 
                      onChange={onChangeWeek}
                      style={{ flexGrow: 1 }}
                      tabBarExtraContent={
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <div style={{ marginLeft: 8 }}>
                            <Button 
                              type="text" 
                              style={{ 
                                padding: '4px 12px', // Відступи для кнопки
                                color: '#595959', 
                                fontSize: 14 
                              }}
                              onClick={onAddTab}
                            >
                              + Add
                            </Button>
                          </div>
                        </div>
                      }
                    />
                  </div>
{/*                 </SortableContext>
              </DndContext>*/}
              <PlanCalendar
                planId={plan.id}
                planTasks={planTasks}
                addTaskToPlan={addTaskToPlan}
                updateTaskPlan={updateTaskPlan}
                planScenarios={planScenarios}
                scenariosList={scenarios}
                addScenarioToPlan={addScenarioToPlan}
                updateScenariosPlan={updateScenariosPlan}
                refreshPlan={refreshPlan}
                weekIndex={activeWeek}
              />
            </Card>
          </div>
          <RemoveModalComponent
            isModalOpen={isRemoveModalOpen}
            handleOk={handleRemoveConfirm}
            handleCancel={closeRemoveModal}
            title={`Are you sure you want to remove the "Week ${selectedWeekNumber()}"?`}
          />
        </>
      )}
    </div>
  );
};

export default PlanPage;
