import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const getScenarios = (query) => axios.get(toQueryString("api/v1/scenario-managements?", query));

const getScenarioById = (id) => axios.get(`api/v1/scenario-managements/${id}`);

const deleteScenario = (id) =>
  axios.delete(`api/v1/scenario-managements/${id}`);

const updateScenario = (id, params) =>
  axios.put(`api/v1/scenario-managements/${id}`, params);

const updateScenarioVersion = (scenarioVersionId, params) =>
  axios.put(`api/v1/scenario-managements/version/${scenarioVersionId}`, params);

const createScenario = () => axios.post("api/v1/scenario-managements");

const activateScenario = (versionId) =>
  axios.put(`api/v1/scenario-managements/version/${versionId}/activate`);

const discardScenarioChanges = (versionId) =>
  axios.put(`api/v1/scenario-managements/version/${versionId}/discard`);

const getDailyTasks = () => axios.get("api/v1/daily-tasks");

const duplicateScenario = (scenarioId) =>
  axios.post(`api/v1/scenario-managements/${scenarioId}/duplicate`);

const getAlternativeMessages = (originalMessage, alreadyGeneratedMessages) =>
  axios.post(`api/v1/scenario-managements/alternative-messages`, {
    originalMessage,
    alreadyGeneratedMessages,
  });

const getAllMessages = async () => {
  const response = (await axios.get(`api/v1/scenario-managements/all-messages`))
    .data;

  return {
    data: response.data,
  };
};

const getOptionsByMessage = async (message) => {
  const response = (
    await axios.get(`api/v1/scenario-managements/question-responses`, {
      params: {
        message,
      },
    })
  ).data;

  return {
    data: response.data,
  };
};

const getSimulatorResources = (scenarioVersionId) =>
  axios.get(
    `api/v1/scenario-managements/version/${scenarioVersionId}/resources`
  );

const ApiScenariosManagement = {
  getScenarios,
  getScenarioById,
  deleteScenario,
  updateScenario,
  updateScenarioVersion,
  createScenario,
  activateScenario,
  discardScenarioChanges,
  getDailyTasks,
  duplicateScenario,
  getAlternativeMessages,
  getAllMessages,
  getOptionsByMessage,
  getSimulatorResources,
};

export default ApiScenariosManagement;
