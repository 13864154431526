import React, {forwardRef} from "react";
import {Input} from "antd";
import classNames from "classnames";

export const FormHeaderTextarea = forwardRef(
  (
    {
      name,
      defaultValue,
      maxLength,
      readonly,
      editMode,
      disabled,
      placeholder,
      ...rest
    },
    ref
  ) => {
    return (
      <Input.TextArea
        className={classNames("lf-form-element", "lf-form-element-header", {
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        type="text"
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        placeholder={placeholder}
        data-1p-ignore
        autoSize={{ minRows: 1, maxRows: 1000 }}
        {...rest}
        ref={ref}
        style={{marginLeft: -12}}
      />
    );
  }
);
