import axios from "axios";
import toQueryString from "../common/utils/to-query-string";

const get = (query) => axios.get(toQueryString(`/api/v1/pages?`, query));

const create = (params) => axios.post(`/api/v1/pages`, params);

const update = (id, params) => axios.put(`/api/v1/pages/${id}`, params);

const remove = (id) => axios.delete(`/api/v1/pages/${id}`);

const fetchIcon = (query) => axios.get(toQueryString(`/api/v1/pages/fetch-icon?`, query));

const getTags = () => axios.get("/api/v1/tags");

const getCategories = () => axios.get("/api/v1/categories");

const ApiArticles = {
  get,
  create,
  update,
  remove,
  getTags,
  fetchIcon,
  getCategories,
};

export default ApiArticles;
