import { Provider } from "react-redux";
import { I18nextProvider, initReactI18next } from "react-i18next";
import { ConfigProvider } from "antd";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import i18next from "i18next";
import store from "./store";
import Login from "./modules/login/login";
import en from "./common/locales/en.json";
import UserManagament from "./modules/administration/user-management/user-managament";
import axios from "axios";
import PrivateRoutes from "./common/utils/private-routes";
import ResetPass from "./modules/login/reset-password";
import NewPass from "./modules/login/new-password";
import SubjectManagement from "./modules/subjects-management/subject-management";
import SubjectLayout from "./modules/subjects-management/subject/subject-layout";
import AuditTrail from "./modules/audit-trail/audit-trail";
import Dashboard from "./modules/dashboard/dashboard";
import { Administration } from "./modules/administration/administration";
import TaskManagement from "./modules/administration/task-management/task-management";
import { ArticleManagement } from "./modules/administration/article-management/article-management";
import LifeFacts from "./modules/administration/life-facts/life-facts";
import PlanManagement from "./modules/administration/plan-management/plan-management";
import LifePoints from "./modules/administration/life-points/life-points";
import CategoryManagement from "./modules/administration/category-management/category-management";
import CohortManagement from "./modules/administration/cohort-management/cohort-management";
import ScenarioManagement from "./modules/administration/scenario-management/scenario-management";
import OnboardingManagement from "./modules/administration/onboarding-management/onboarding-management";
import LifeBot from "./modules/administration/life-bot/life-bot";
// import UserManagament from "./modules/administration/user-management/user-managament";

import "./App.css";

console.log(process.env.REACT_APP_DEV_URL, document.location.origin);
axios.defaults.baseURL =
  process.env.REACT_APP_DEV_URL || document.location.origin;

i18next.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      translation: en,
    },
  },
  interpolation: { escapeValue: false },
});


// const ProblematicComponent = () => {
//   throw new Error("Test error");
//   return <div>debbug code</div>;
// };


setTimeout(() => {
  document.title = window.appName;
}, 1000);

const App = () => (
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "#0141E4",
            borderRadius: "5px",
            fontFamily: "Sora",
          },
          components: {
            Select: {
              optionSelectedBg: "var(--shade-color)",
            },
          },
        }}
      >
        <Router>
          <Routes>
            <Route element={<Login />} path="/login" exact />
            <Route element={<ResetPass />} path="login/reset" />
            <Route element={<NewPass />} path="login/new-password" />
            <Route element={<PrivateRoutes />}>
              <Route
                path="*"
                element={<Navigate to="/dashboard" replace={true} />}
              />
              <Route element={<Dashboard />} path="/dashboard" exact />
              <Route
                element={<SubjectManagement />}
                path="/subject-management"
                exact
              />
              <Route
                element={<UserManagament />}
                path="/user-management"
                exact
              />
              <Route
                element={<Administration />}
                path="/administration"
                exact
              />
              <Route path="/administration" element={<Administration />}>
                <Route index element={<TaskManagement />} />
                <Route path="tasks" element={<TaskManagement />} />
                <Route path="tasks/create" element={<TaskManagement mode="create" />} />
                <Route path="tasks/:id" element={<TaskManagement mode="show" />} />
                <Route path="tasks/:id/edit" element={<TaskManagement mode="edit" />} />
                <Route path="articles" element={<ArticleManagement />} />
                <Route path="articles/create" element={<ArticleManagement mode="create" />} />
                <Route path="articles/:id" element={<ArticleManagement mode="show" />} />
                <Route path="articles/:id/edit" element={<ArticleManagement mode="edit" />} />
                <Route path="fitfacts" element={<LifeFacts />} />
                <Route path="fitfacts/create" element={<LifeFacts mode="create" />} />
                <Route path="fitfacts/:id" element={<LifeFacts mode="show" />} />
                <Route path="fitfacts/:id/edit" element={<LifeFacts mode="edit" />} />
                <Route path="plans" element={<PlanManagement />} />
                <Route path="plans/:id" element={<PlanManagement mode="show" />} />
                <Route path="lifepoints" element={<LifePoints />} />
                <Route path="categories" element={<CategoryManagement />} />
                <Route path="cohorts" element={<CohortManagement />} />
                <Route path="scenarios" element={<ScenarioManagement />} />
                <Route path="scenarios/:id" element={<ScenarioManagement mode="show" />} />
                <Route path="onboardings" element={<OnboardingManagement />} />
                <Route path="onboardings/:id" element={<OnboardingManagement mode="show" />} />
                <Route path="system-users" element={<UserManagament />} />
                <Route path="disabled-users" element={<UserManagament />} />
                <Route path="pending-users" element={<UserManagament />} />
                <Route path="system-users/create" element={<UserManagament mode="create" />} />
                <Route path="system-users/:id" element={<UserManagament mode="show" />} />
                <Route path="system-users/:id/edit" element={<UserManagament mode="edit" />} />
                <Route path="disabled-users/create" element={<UserManagament mode="create" />} />
                <Route path="disabled-users/:id" element={<UserManagament mode="show" />} />
                <Route path="disabled-users/:id/edit" element={<UserManagament mode="edit" />} />
                <Route path="pending-users/create" element={<UserManagament mode="create" />} />
                <Route path="pending-users/:id" element={<UserManagament mode="show" />} />
                <Route path="pending-users/:id/edit" element={<UserManagament mode="edit" />} />
                <Route path="LifeBot" element={<LifeBot />} />
              </Route>
              <Route
                element={<SubjectLayout />}
                path="/subject-management/subject"
              />
              <Route
                element={<SubjectLayout />}
                path="/subject-management/subject/:id"
              />
              <Route element={<AuditTrail />} path="/audit-trail" exact />
            </Route>
          </Routes>
        </Router>
      </ConfigProvider>
    </I18nextProvider>
  </Provider>
);

export default App;
