import React, {forwardRef} from "react";
import {Button} from "antd";
import classNames from "classnames";

export const FormInputButton = forwardRef(
  ({ value, onClick, readonly, ...rest }, ref) => {
    return (
      <Button
        className={classNames(
          "lf-form-element",
          "lf-form-element--type-input-button"
        )}
        type="text"
        onClick={onClick}
        style={{ pointerEvents: readonly ? "none" : undefined }}
        {...rest}
        ref={ref}
      >
        {value}
      </Button>
    );
  }
);
