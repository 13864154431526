import axios from "axios";

const reorderWeek = (weeklyScheduleId, weekIndex) => {
  return axios.put(`api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}/reorder`, { weekIndex });
};

const getPlans = () => axios.get("api/v1/plan-managements");

const createPlan = () => axios.post("api/v1/plan-managements");

const getPlanById = (id) => axios.get(`api/v1/plan-managements/${id}`);

const updateWeeklySchedule = (planId, task) => {
  console.log(planId, task);
  return axios.put(
    `api/v1/plan-managements/${planId}/upsertWeeklyScheduleActivity`,
    {
      ...task,
      dailyTaskId: task.DailyTask?.id ?? null,
      scenarioId: task.scenarioId,
    }
  );
};
const updatePlan = (id, field) =>
  axios.put(`api/v1/plan-managements/${id}`, field);

const updatePlanCohort = (planId, groupScheduleIds) =>
  axios.put(
    `api/v1/plan-managements/${planId}/update`,
    groupScheduleIds
  );

const deletePlan = (id) => axios.delete(`api/v1/plan-managements/${id}`);

const deleteActivity = (planId, activityId) =>
  axios.delete(
    `api/v1/plan-managements/${planId}/deleteWeeklyScheduleActivity/${activityId}`
  );

const activatePlan = (id) =>
  axios.put(
    `api/v1/plan-managements/${id}/activate`
  );

const discardPlan = (id) =>
  axios.put(
    `api/v1/plan-managements/${id}/discard`
  );

const duplicatePlan = (id) =>
  axios.post(`api/v1/plan-managements/${id}/duplicate`);

const addWeek = (id) =>
  axios.post(`api/v1/plan-managements/${id}/add-week`);

const duplicateWeek = (weeklyScheduleId) =>
  axios.post(`api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}/duplicate-week`);

const deleteWeek = (weeklyScheduleId) =>
  axios.delete(`api/v1/plan-managements/weeklySchedule/${weeklyScheduleId}`);

const ApiPlanManagement = {
  getPlans,
  getPlanById,
  createPlan,
  updateWeeklySchedule,
  updatePlan,
  deletePlan,
  deleteActivity,
  activatePlan,
  discardPlan,
  updatePlanCohort,
  duplicatePlan,
  addWeek,
  duplicateWeek,
  deleteWeek,
  reorderWeek
};

export default ApiPlanManagement;
