import {Switch} from "antd";
import React from "react";

export const FormSwitch = ({ checked, onChange, readOnly }) => {
  return (
    <div className="lf-form-switch">
      <Switch onChange={onChange} checked={checked} disabled={readOnly} />
    </div>
  );
};
