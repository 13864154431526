import { Button, Input } from "antd";
import "./styles.css";
import { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { selectIsAdmin } from "../../store/slices/user";
import { Icon } from "../icon/icon";
import debounce from "../../common/utils/debounce";

const Header = ({
  filters,
  title,
  search,
  create,
  apply,
  cancel,
  className,
  isBold,
  period,
}) => {
  const isAdmin = useSelector(selectIsAdmin);

  const [inputValue, setInputValue] = useState(search?.value || "");

  const debouncedOnChange = useCallback(
    debounce((value) => {
      search && search.onChange && search.onChange(value);
    }, 400),
    [search?.onChange]
  );

  const handleChange = (e) => {
    if (e && e.target && e.target.value) {
      const newValue = e.target.value;
      setInputValue(newValue);
      debouncedOnChange(newValue);
    } else {
      setInputValue(null);
      debouncedOnChange(null);
    }
  };

  return (
    <div className={`assets-header ${className || ""}`}>
      <div className="assets-header__wrapper">
        <div className="assets-header__left">
          {isBold ? (
            <h2>{title}</h2>
          ) : (
            <h3 style={{ opacity: "0.6" }}>{title}</h3>
          )}
          {filters}
          {period}
        </div>
        <div className="assets-header__right">
          {search && (
            <Input
              rootClassName={"assets-header__search"}
              value={inputValue} // Використовуємо локальний стан
              onChange={handleChange}
              size="large"
              placeholder={search.placeholder}
              prefix={<Icon name={"search"} color={"text-1"} />}
              className="assets-header__search"
              maxLength={1000}
            />
          )}
          {create && isAdmin && (
            <Button
              type="primary"
              className="assets-header__add-btn"
              onClick={create.onClick}
            >
              {create.label}
            </Button>
          )}
          {apply && (
            <Button
              type="primary"
              className="assets-header__apply-btn"
              onClick={apply.onClick}
            >
              {apply.label}
            </Button>
          )}
          {cancel && (
            <Button
              type="secondary"
              className="assets-header__cancel-btn"
              style={{ background: "rgba(108, 108, 137, 0.08)" }}
              onClick={cancel.onClick}
            >
              {cancel.label}
            </Button>
          )}

          {create && create.renderModalComponent}
        </div>
      </div>
    </div>
  );
};

export default Header;