import { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { Body } from "../../../components/main-body/body";
import editIcon from "../../../assets/edit.svg";
import { useTranslation } from "react-i18next";
import useNotification from "../../../components/notification/notification";
import "./life-bot.css";
import ApiLifeBotManagement from "../../../api/lifebot";
import { FormInput } from "../../../components/form/form";
import classNames from "classnames";

const LifeBot = () => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { openNotification } = useNotification();
  const [settings, setSettings] = useState([]);
  const [initialList, setInitial] = useState([]);
  const [form] = Form.useForm();

  const cancel = () => {
    setIsEdit(false);
    setHasChanges(false);
    setSettings(initialList);
  };

  const toogleEdit = () => {
    setIsEdit(!isEdit);
    setHasChanges(false);
  };

  const updateSettings = () => {
    ApiLifeBotManagement.update(settings).then(() => {
      openNotification({
        type: "success",
        message: <p>{t("lifebot.notification.success")}</p>,
      });
    });
    toogleEdit();
  };

  const getSettings = () => {
    ApiLifeBotManagement.get().then((r) => {
      setSettings(r.data.settings);
      setInitial(r.data.settings);
    });
  };

  const onHandleChange = (key, v) => {
    if (!hasChanges) {
      setHasChanges(true);
    }
    setSettings(
      settings.map((item) => {
        if (item.key === key) {
          return { ...item, value: +v.target.value };
        }

        return item;
      })
    );
  };

  useEffect(() => {
    getSettings();
  }, []);

  return (
    <Body header={{ title: t("lifebot.title") }}>
      <div className="profile_form-container">
        <div className="profile_form__header">
          <span className="profile_form__header__title">
            {t("lifebot.settings")}
          </span>
          <div style={{ height: 40 }}>
            {isEdit ? (
              <div className="profile-header__btns">
                <Button
                  style={{
                    height: 40,
                    background: "rgba(108, 108, 137, 0.08)",
                  }}
                  onClick={cancel}
                >
                  {t("profile.btns.cancel")}
                </Button>
                <Button
                  type="primary"
                  style={{ marginLeft: 8, height: 40 }}
                  onClick={updateSettings}
                  disabled={!hasChanges}
                >
                  {t("profile.btns.save")}
                </Button>
              </div>
            ) : (
              <img
                className="profile_form__header__edit-btn"
                src={editIcon}
                alt="edit"
                role="presentation"
                onClick={toogleEdit}
              />
            )}
          </div>
        </div>

        <div className="lifepoints-content-container">
          <div className="lifepoints-content-item">
            <div>
              {settings.map((item) => (
                <p className="lifepoints-content-item-activity">{item.name}</p>
              ))}
            </div>
          </div>
          <div className="lifepoints-content-item">
            <div>
              <Form form={form}>
                {settings.map((item) => (
                  <FormInput
                    name={item.name}
                    key={item.key}
                    readOnly={!isEdit}
                    type="number"
                    className={classNames("lifepoints-content-item-points", {
                      "lifepoints-content-item-points--edit-mode": isEdit,
                    })}
                    value={item.value}
                    bordered={false}
                    onChange={(v) => onHandleChange(item.key, v)}
                  />
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Body>
  );
};

export default LifeBot;
