import { useEffect, useState, useRef } from "react";
import { assembleWithEmbeddedScenarios } from "./utils/scenarioAssembler";

const getRandomText = (question = {}, firstQuestionText) => {
  let joinedMessages = [
    question?.data?.message,
    ...(question?.data?.message_alternatives || []),
  ];

  const hasFirstQuestionTextInJoined =
    joinedMessages.includes(firstQuestionText);

  if (hasFirstQuestionTextInJoined) {
    joinedMessages = [firstQuestionText];
  }

  const randomIndex = Math.floor(Math.random() * joinedMessages?.length);

  const normalizeMessage = {
    message: joinedMessages?.[randomIndex] || question.data?.message,
  };

  const randomText = {
    ...question,
    data: {...question.data, ...normalizeMessage},
  };

  return randomText;
};


const useScenarioSimulatorController = (
  scenario,
  scenarioId,
  embeddedScenarios,
  isReady
) => {
  const currentScenarioRef = useRef(scenario);
  const [history, setHistory] = useState([]);
  const [inProcess, setInProcess] = useState(false);
  const [normalizedEmbeddedScenarios, setNormalizedEmbeddedScenarios] = useState({});
  const shouldResolveEmbeddedScenarios = Object.values(scenario).find(node => node.data.nodeType === 'scenario:picker');

  const processConditionNode = (answer, nextNode) => {
    const normalizedConditionals = nextNode.data?.condStatements?.map(
      state => state.value?.map(v => v.value) ?? null,
    );

    const findConditionIndex = v => {
      if (!v) {
        return false;
      }

      return v.includes(answer?.value);
    };

    let nextNodeIndex = normalizedConditionals?.findIndex(findConditionIndex);

    if (nextNodeIndex === -1) {
      nextNodeIndex = normalizedConditionals?.findIndex(v => !v);
    }

    const jumpToAnotherBranch =
      currentScenarioRef.current[nextNode.nextNodes[nextNodeIndex]];

    toNextQuestion(jumpToAnotherBranch.nextNodes[0], answer);
  };

  const toNextQuestion = (nextQuestionId, answer = null) => {
    setInProcess(true);

    let historyCopy = [...history];

    historyCopy[historyCopy.length - 1] = {
      ...historyCopy[historyCopy.length - 1],
      answer,
    };

    setHistory([...historyCopy]);

    setTimeout(() => {
      const nextQuestion = getRandomText(
        currentScenarioRef?.current[nextQuestionId],
      );

      setHistory([...historyCopy, {scenarioId, ...nextQuestion}]);
      setInProcess(false);
    }, Math.floor(Math.random() * (3000 - 1500) + 1500));
  };

  const rollBack = id => {
    setInProcess(false);

    const selectedHistoryIndex = history.findIndex((i) => i.id === id);
    const newHistory = [...history].slice(0, selectedHistoryIndex);

    const previousQuestion = currentScenarioRef.current[id];
    setHistory([...newHistory, getRandomText(previousQuestion)]);
  };

  const setAnswer = (id, answer, isRollback) => {
    if (inProcess || !currentScenarioRef.current) return;

    if (isRollback) {
      rollBack(id);
      return;
    }

    const nextQuestionId = currentScenarioRef.current[id].nextNodes[0];
    const nextNode = currentScenarioRef.current[nextQuestionId];

    if (!nextNode) {
      return;
    }


    if (nextNode.type === "GenericNode") {
      toNextQuestion(nextQuestionId, answer);
      return;
    }

    if (nextNode.type === "IfConditionNode") {
      processConditionNode(answer, nextNode);
      return;
    }

    if (nextNode.type === "IfCloseNode") {
      toNextQuestion(nextNode.nextNodes[0], answer);
      return;
    }

    toNextQuestion(nextQuestionId, answer);

  };


  const initFirstQuestion = (history = []) => {
    const startNode = Object.values(scenario).find(
      node => node.type === 'StartNode',
    );

    const firstQuestion = currentScenarioRef.current[startNode.nextNodes[0]];

    setHistory([
      ...history,
      {data: {nodeType: 'StartNode'}},
      {...getRandomText(firstQuestion)},
    ]);
  };

  useEffect(() => {
    const lastHistoryItem = history[history.length - 1];
    if (
      lastHistoryItem &&
      !inProcess &&
      ![
        "message:single",
        "message:multi",
        "message:input",
        "message:picker",
        "message:date",
      ].includes(lastHistoryItem?.data?.nodeType)
    ) {
      setAnswer(lastHistoryItem.id);
    }
  }, [history, inProcess]);

  useEffect(() => {
    const normalizedItems = {};
    const getNormalizedItemsForScenario = (scenario, id) => {
      if (scenario && scenario.data) {
        normalizedItems[id] = JSON.parse(scenario.data);
      }
      if(scenario && scenario.embeddedScenarios && Object.keys(scenario.embeddedScenarios).length) {
        Object.keys(scenario.embeddedScenarios).forEach((key) => {
          return getNormalizedItemsForScenario(scenario.embeddedScenarios[key], key);
        });
      }
    }
    Object.keys(embeddedScenarios).forEach((key) => {
      getNormalizedItemsForScenario(embeddedScenarios[key], key);
    });
    setNormalizedEmbeddedScenarios(normalizedItems);
  }, [embeddedScenarios]);

  useEffect(() => {
    if (
      isReady
      && !history.length
      && ((shouldResolveEmbeddedScenarios && Object.keys(normalizedEmbeddedScenarios).length) || !shouldResolveEmbeddedScenarios)
    ) {
      const assembledScenario = assembleWithEmbeddedScenarios(currentScenarioRef.current, normalizedEmbeddedScenarios);
      currentScenarioRef.current = assembledScenario.result;
      initFirstQuestion(history);
    }
  }, [isReady, normalizedEmbeddedScenarios]);

  return {
    history,
    setAnswer,
    toNextQuestion,
    inProcess,
  };
};

export default useScenarioSimulatorController;
