import React from "react";
import {Icon} from "../../icon/icon";
import UploadAvatar from "../../upload-avatar/upload-avatar";

export const FormAvatar = ({ value, onChange, readonly, ...rest }) => {
  if (readonly) {
    return (
      <div
        className="lf-form-avatar"
        style={{ backgroundImage: value ? `url(${value})` : undefined }}
      >
        {!value && <Icon name={"user"} size={18} color={"text-2"} />}
      </div>
    );
  }
  return (
    <div className="lf-form-avatar">
      <UploadAvatar onChange={onChange} value={value} />
    </div>
  );
};
