import {Skeleton} from "antd";
import React from "react";

const ImgSkeletonStyle = {
  zoom: 3,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  width: "auto",
  height: "auto",
};

export const ImgSkeleton = ({ active, withIcon = false }) => {
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
        width: "100%",
      }}
    >
      {withIcon ? (
        <Skeleton.Image style={ImgSkeletonStyle} active={active} />
      ) : (
        <Skeleton.Button style={ImgSkeletonStyle} active={active} />
      )}
    </div>
  );
};
