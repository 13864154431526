import axios from "axios";

const get = () => axios.get("api/v1/settings/lifebot");

const add = (points) => axios.post("api/v1/settings", points);

const update = (list) => axios.put(`api/v1/settings/lifebot`, { list });

const remove = (id) => axios.delete(`api/v1/settings/${id}`);

const ApiLifeBotManagement = {
  get,
  add,
  update,
  remove,
};

export default ApiLifeBotManagement;
