import { DrawerHeader } from "../../../../components/drawer/drawer-header";
import {
  FormHeaderTextarea,
  FormTextarea,
} from "../../../../components/form/form";
import { memo } from "react";
import { useTaskEdit } from "./task-edit.hook";
import { Drawer } from "../../../../components/drawer/drawer";
import { Icon } from "../../../../components/icon/icon";
import { PageList, PageListAdd } from "./page-list";
import { TaskIconPicker } from "./icon-picker";
import { QuestionList } from "./question-list";
import { BlockAdd } from "../../../../components/block/block";
import { CategorySelector } from "./category-selector";

const TaskCardInside = memo(
  ({
    task,
    categories,
    close,
    editMode,
    createTask,
    updateTask,
    cancelEditing,
    deleteTask,
    toEditMode,
    pages = [],
    onlyView,
  }) => {
    const control = useTaskEdit({
      task,
      createTask,
      updateTask,
      pages,
      cancelEditing,
      close,
    });
    return (
      <>
        <DrawerHeader
          title={
            <TaskIconPicker
              task={control.icon.value}
              onIconSelect={editMode ? control.icon.update : undefined}
            />
          }
          isEditing={editMode}
          onEdit={onlyView ? undefined : toEditMode}
          onClose={close}
          onSave={control.save}
          onCancel={control.cancel}
          menu={
            onlyView
              ? undefined
              : {
                  items: [
                    {
                      label: "Delete",
                      icon: (
                        <Icon
                          name={"delete"}
                          style={{ marginRight: 8, marginLeft: -4 }}
                        />
                      ),
                      onClick: deleteTask,
                    },
                  ],
                  onClick: (e) => control.category.change(e.key),
                }
          }
        />
        <div style={{ paddingBottom: 24 }} />
        <div>
          <FormHeaderTextarea
            readonly={!editMode}
            placeholder={"Title"}
            defaultValue={control.tile.value}
            onChange={control.tile.update}
            maxLength={100}
          />
          <div style={{ paddingBottom: 4 }} />
          <FormTextarea
            readonly={!editMode}
            placeholder={"Description"}
            defaultValue={control.description.value}
            onChange={control.description.update}
            maxLength={1000}
            style={{
              fontSize: 16,
            }}
          />
        </div>
        <div style={{ paddingBottom: 24 }}></div>
        <CategorySelector
          categories={categories}
          control={control}
          editMode={editMode}
        />
        <QuestionList control={control} editMode={editMode} />
        <BlockAdd
          title={"Add question"}
          onClick={control.questions.add}
          hide={!editMode}
        />
        <PageList
          pages={control.pages.value}
          editMode={editMode}
          onRemove={control.pages.remove}
        />
        <PageListAdd
          pages={pages.filter(
            (page) => !control.pages.value.some((_page) => _page.id === page.id)
          )}
          onAdd={control.pages.add}
          editMode={editMode}
        />
      </>
    );
  }
);

const TaskCard = ({
  task,
  categories,
  isOpen,
  close,
  editMode,
  createTask,
  updateTask,
  deleteTask,
  toEditMode,
  cancelEditing,
  pages,
  onlyView,
}) => {
  return (
    <Drawer open={isOpen} width={800} closable={false} onClose={close}>
      <div style={{ paddingBottom: 48 }}>
        {isOpen && (
          <TaskCardInside
            task={task}
            categories={categories}
            createTask={createTask}
            close={close}
            cancelEditing={cancelEditing}
            updateTask={updateTask}
            deleteTask={deleteTask}
            toEditMode={toEditMode}
            editMode={editMode}
            pages={pages}
            onlyView={onlyView}
          />
        )}
      </div>
    </Drawer>
  );
};

export default TaskCard;
