import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PlanTable from "./components/plan-table";
import { Body } from "../../../components/main-body/body";
import ApiPlanManagement from "../../../api/plan-management";
import PlanPage from "./components/plan-page";
import "./plan-management.css";
import useNotification from "../../../components/notification/notification";
import { useParams, useNavigate, useLocation } from "react-router-dom";

const PlanManagement = () => {
  const { t } = useTranslation();
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setPlan] = useState();
  const [cohorts, setCohorts] = useState([]);
  const [scenarios, setScenarios] = useState([]);
  const { openNotification } = useNotification();
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigate = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const getPlans = (disabledPlan) => {
    ApiPlanManagement.getPlans().then(({ data }) => {
      const { plans, groupSchedules, scenarios } = data || {};
      setPlans(plans);
      setCohorts(
        groupSchedules.map((cohort) => ({
          label: cohort.EnrollmentFieldOption.value,
          id: cohort.id,
        }))
      );

      setScenarios(scenarios);

      if (disabledPlan) {
        setPlan();
      } else if (selectedPlan) {
        setPlan(plans.find((plan) => plan.id === selectedPlan.id));
      }
    });
  };

  const createNewPlan = () => {
    ApiPlanManagement.createPlan()
      .then((r) => {
        getPlans(true);

        openNotification({
          type: "success",
          message: (
            <p>
              {t("plan_management.notification.created", {
                plan: r.data.plan.title,
              })}
            </p>
          ),
        });
      })
      .catch(() => {
        openNotification({
          type: "error",
          message: <p>{t("plan_management.notification.error")}</p>,
        });
      });
  };

  const headerProps = useMemo(
    () => ({
      title: "Plan Management",
      create: {
        label: t("plan_management.create"),
        onClick: () => createNewPlan(),
      },
    }),
    []
  );

  const handleSelectPlan = (plan) => {
    setPlan(plan);
    handleNavigate(`/administration/plans/${plan.id}`);
  };

  const handleDeselectPlan = () => {
    getPlans(true);
    handleNavigate(`/administration/plans`);
  };

  const removePlan = (planId, planTitle) => {
    ApiPlanManagement.deletePlan(planId).then(() => {
      getPlans();

      openNotification({
        type: "success",
        message: (
          <p>
            {t("plan_management.notification.deleted", {
              plan: planTitle,
            })}
          </p>
        ),
      });
    });
  };

  const duplicatePlan = (id) => {
    ApiPlanManagement.duplicatePlan(id).finally(() => {
      getPlans();
    });
  };

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (id && plans.length) {
      const plan = plans.find((plan) => plan.id === id);
      if (plan) {
        setPlan(plan);
      } else {
        setPlan(null);
      }
    } else {
      setPlan(null);
    }
  }, [id, plans]);

  useEffect(() => {
    if (!id && selectedPlan) {
      setPlan(null);
    }
  }, [location.pathname, id, selectedPlan]);

  return selectedPlan ? (
    <PlanPage
      plan={selectedPlan}
      scenarios={scenarios}
      cohorts={cohorts}
      toList={handleDeselectPlan}
      refreshPlan={getPlans}
      openNotification={openNotification}
    />
  ) : (
    <Body header={headerProps}>
      <PlanTable
        data={plans}
        onHandleRowClick={handleSelectPlan}
        removePlan={removePlan}
        duplicatePlan={duplicatePlan}
      />
    </Body>
  );
};

export default PlanManagement;
