import React, {forwardRef} from "react";
import {Input} from "antd";
import classNames from "classnames";

export const FormTextarea = forwardRef(
  (
    { name, defaultValue, maxLength, readonly, editMode, disabled, ...rest },
    ref
  ) => {
    return (
      <Input.TextArea
        className={classNames("lf-form-element", {
          "lf-form-element--edit-mode": !readonly,
        })}
        name={name}
        type="text"
        readOnly={readonly}
        defaultValue={defaultValue}
        maxLength={maxLength}
        autocomplete="off"
        disabled={disabled}
        data-1p-ignore
        autoSize={{ minRows: 1, maxRows: 1000 }}
        {...rest}
        ref={ref}
      />
    );
  }
);
