import { Progress } from "antd";
import "../styles.css";
import TasksTable from "./tasks-table";
import Block, { BlockSection } from "../../../../components/block/block";
import { Body } from "../../../../components/main-body/body";
import DropdownFilters from "../../../../components/dropdown-filters/dropdown-filters";

const getUniq = (data = []) =>
  data.filter((value, index) => data.indexOf(value) === index);

const normStatusName = (status) => {
  switch (status) {
    case "COMPLETED":
      return "Complete";
    case "INCOMPLETED":
      return "Incomplete";
    case "PENDING":
      return "Pending";
    case "DISSMISED":
      return "Dismissed";
    default:
      return "N/A";
  }
};

const twoColors = {
  "0%": "#2762f5",
  "100%": "rgba(54, 179, 126, 1)",
};

const twoColorsDone = {
  "0%": "rgba(54, 179, 126, 1)",
  "100%": "rgba(54, 179, 126, 1)",
};

const SubjectTasks = ({ tasks, analytics, updateFilterValue, titles, currentWeek }) => {
  if (!analytics) return;

  const { rate, completed, dismissed, incompleted, pending } = analytics; 

  const titlesList = getUniq(titles).map((title) => ({
    name: title,
    id: title,
  }));

  const statusesList = ["COMPLETED", "DISSMISED", "PENDING", "INCOMPLETED"].map(
    (status) => ({
      name: normStatusName(status),
      id: status,
    })
  );

  const filterItems = [
    { name: "Task name", id: "title", children: titlesList },
    { name: "Due date", id: "period", period: true },
    { name: "Status", id: "status", children: statusesList },
  ];

  return (
    <Body
      header={{ title: "Tasks" }}
      filters={
        <DropdownFilters
          filtersBy={filterItems}
          updateFilterValue={updateFilterValue}
        />
      }
      scroll
    >
      <Block fixThead>
        <BlockSection>
          <div className="survey__items-container">
            <div className="profile_form-item survey__item">
              <Progress
                type="circle"
                percent={Math.floor(rate * 100)}
                strokeColor={rate === "1.00" ? twoColorsDone : twoColors}
                format={() => ""}
                size={56}
                strokeWidth={20}
              />
              <div>
                <p>{Math.floor(rate * 100)}%</p>
                <p className="survey__item__description">Adherence rate</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{completed}</p>
                <p className="survey__item__description">Complete</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{dismissed}</p>
                <p className="survey__item__description">Dismissed</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{pending}</p>
                <p className="survey__item__description">Pending</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{incompleted}</p>
                <p className="survey__item__description">Incomplete</p>
              </div>
            </div>
            <div className="profile_form-item survey__item">
              <div>
                <p>{currentWeek}</p>
                <p className="survey__item__description">Current week</p>
              </div>
            </div>
          </div>
        </BlockSection>
        <TasksTable tasks={tasks} />
      </Block>
    </Body>
  );
};

export default SubjectTasks;
